import classNames from 'classnames'
import xss from 'xss'

import { toSnakeCase } from '~/utils/to-snake-case'
import { Website_Section } from '~/website.models'

export function Sections({ sections }: { sections: Website_Section[] }) {
  return sections && sections.length ? (
    <>
      {sections.map((section, index) => (
        <div
          className={classNames('section', {
            // alt: index % 2 === 0,
            custom: section.is_custom,
          })}
          key={index}
          id={section.title ? toSnakeCase(section.title) : undefined}
          style={{
            ...(section.bg_color && { backgroundColor: section.bg_color }),
            ...(section.padding && { padding: section.padding + 'px 0' }),
            ...(section.text_color && { color: section.text_color }),
          }}
        >
          {section.is_custom ? (
            <>
              {section.title && <h1>{section.title}</h1>}
              {section.subtitle && (
                <div className="section-subtitle">{section.subtitle}</div>
              )}
              {section.html && (
                <div
                  dangerouslySetInnerHTML={{ __html: section?.html || '' }}
                ></div>
              )}
            </>
          ) : (
            <>
              {section.title_above && (
                <>
                  {section.title && <h1>{section.title}</h1>}

                  {section.subtitle && (
                    <div className="section-subtitle">{section.subtitle}</div>
                  )}
                </>
              )}

              <div className="container split">
                {section.image_url ? (
                  <div className="section-col">
                    <img
                      className="section-img"
                      src={section.image_url}
                      alt={section.title}
                      style={
                        section.image_radius
                          ? { borderRadius: section.image_radius + 'px' }
                          : {}
                      }
                    />
                  </div>
                ) : null}

                <div className="section-col">
                  {!section.title_above ? (
                    <>
                      {section.title && <h1>{section.title}</h1>}

                      {section.subtitle && (
                        <div className="section-subtitle">
                          {section.subtitle}
                        </div>
                      )}
                    </>
                  ) : null}

                  <div
                    className="blurb"
                    dangerouslySetInnerHTML={{
                      __html: xss(section.html || ''),
                    }}
                  ></div>

                  {section.cta_url && (
                    <a
                      href={`https://${section.cta_url}`}
                      className="btn small"
                      {...(section.cta_new_window ? { target: '_blank' } : {})}
                    >
                      {section.cta_label ?? ''}
                    </a>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </>
  ) : null
}
